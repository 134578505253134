html {
  height: 100%;
  background-color: #ffffff;
}

body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-progress-spinner circle, .mat-spinner circle {
stroke: #1890FF;
}

a {
  color: #1890ff;
}